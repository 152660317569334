import React from 'react'
import { graphql } from 'gatsby'
import NavBlogPostTemplate from './nav-blog-post'
import startCase from 'lodash/startCase'

class SeriesBlogPost extends React.Component {
  render () {
    const post = this.props.data.mdx
    const edges = this.props.data.seriesMdx.edges
    const { series } = this.props.pageContext
    const navTitle = startCase(series)

    const navList = []

    edges.forEach(edge => {      
      const navItem = {
        title: edge.node.frontmatter.short_title,
        slug: edge.node.fields.slug
      }
      navList.push(navItem)    
    });

    return (
      <NavBlogPostTemplate
        siteMetadata={this.props.data.site.siteMetadata}
        location={this.props.location}
        post={post}
        navTitle={navTitle}
        navList={navList} />
    )
  }
}

export default SeriesBlogPost

export const pageQuery = graphql`
  query SeriesBlogPostBySlug($slug: String!, $series: String!) {
    site {
      siteMetadata {
        name
        title
        description
        author
        disqusShortname
        mailchimpEndpoint
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      timeToRead
      body
      fields {
        slug
      }
      frontmatter {
        title
        author
        description
        date(formatString: "MMMM DD, YYYY")
        categories
        group
        image {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 960)
          }
        }
      }
    }
    seriesMdx: allMdx(
      sort: { fields: [frontmatter___series_weight], order: ASC },
      filter: { 
        frontmatter: { 
          status: { ne: "draft" }
          series: { in: [$series] }
        } 
      } 
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            short_title            
          }
        }
      }
    } 
  }
`
